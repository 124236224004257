<template>
  <v-app>
    <slot />

    <global-snackbar />
  </v-app>
</template>

<script>
import GlobalSnackbar from '@/components/global-snackbar'

export default {
  name: 'logged-out-fullscreen-layout',
  components: { GlobalSnackbar },
}
</script>

<style lang="scss" scoped>
</style>
